import React, { forwardRef } from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Typography, Grid, Divider, useMediaQuery, Box } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui';
import Markdown from '../components/markdown';
import { SLIDE_SCALE, DIST_RATIO, LOGO_SCALE } from '../constants';

import theme from '../gatsby-theme-material-ui-top-layout/theme';

const styles = {
  root: {},
  logo: {
    filter:
      'drop-shadow( 0 0 2px rgba(0, 0, 0, 0.7)) drop-shadow( 0 0 8px rgba(255, 255, 255, 1))',
  },
  logoTypeSubtext: {
    textAlign: 'center',
    color: '#000',
    transform: `scale(${LOGO_SCALE * 0.93})`,
  },
  imgTest: {
    width: 400,
    height: 'auto',
  },
  imgSlide: {
    height: `${(9 / 16) * 100}vw`,
    transform: `scale(${SLIDE_SCALE})`,
    boxShadow:
      '0px 0px 20px 4px rgba(0,0,0,0.3), inset 0px 0px 20px 4px rgba(0,0,0,0.3)',
    opacity: 1,
  },
  coverTextSlideWrapper: {
    width: '80vw',
    height: '70vh',
    '& p': {
      textShadow:
        '0px 0px 16px #fff, 0px 0px 8px #fff, 0px 0px 8px #fff, 0px 0px 4px #fff',
    },
  },
  coverTextTitle: {
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  contentSlideRoot: {
    maxWidth: {
      xs: '90%',
      md: '60%',
    },
  },
  contentSlideWrapper: {
    transform: `scale(${1 - DIST_RATIO})`,
    '& > div > p': {
      color: '#fff',
    },
    '& > div > h3': {
      color: '#fff',
    },
    '& > div > button': {
      margin: theme.spacing(1),
    },
  },
  contentSlideType: {
    textShadow: '0px 0px 16px #fff, 0px 0px 8px #000',
  },
  contentSlideTypeTextInImage: {
    textShadow: '0px 0px 75px #000, 0px 0px 45px #000, 0px 0px 8px #000',
  },
  categoryWrapper: {
    textAlign: 'center',
    color: '#000',
    transform: `scale(${LOGO_SCALE * 0.93})`,
    '& > a': {
      color: '#000',
      fontSize: '2.125rem',
      margin: `0 ${theme.spacing(3)}`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
    },
  },
  divider: {
    background: '#000',
    width: {
      xs: '30vw',
      md: 3,
    },
    margin: {
      xs: '0 35vw',
      md: 'initial',
    },
    height: {
      xs: 2,
      md: 'initial',
    },
  },
  logo: {
    filter:
      'drop-shadow( 0 0 2px rgba(0, 0, 0, 0.7)) drop-shadow( 0 0 8px rgba(255, 255, 255, 0.6)) invert(1)',
  },
  logoTypeSubtext: {
    textAlign: 'center',
    color: '#000',
    transform: `scale(${LOGO_SCALE * 0.93})`,
  },
  logoSlideWrapper: {
    transform: `scale(2)`,
  },
  logoImgWrapper: {
    '& > img': {
      width: {
        xs: '130px',
        md: '300px',
      },
    },
  },
  subLogoButtonWrapper: {
    marginTop: '15vh',
  },
};

export const LogoSlide = forwardRef((props, ref) => {
  const { logo, text, ...otherProps } = props;

  return (
    <div {...otherProps} ref={ref}>
      <Grid
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
        sx={styles.logoSlideWrapper}
      >
        <Grid item sx={styles.logoImgWrapper}>
          <img
            src="/bva-logo-full.svg"
            alt="Bureau V Architecture Logo"
            style={styles.logo}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export const LogoSubTextSlide = forwardRef((props, ref) => {
  const { text, ...otherProps } = props;

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div {...otherProps} ref={ref}>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="flex-start"
        sx={styles.logoSlideWrapper}
      >
        <Grid item xs={12} sx={styles.subLogoButtonWrapper}>
          <Grid
            container
            alignItems="center"
            drection={smDown ? 'column' : 'row'}
            justifyContent="center"
            sx={styles.categoryWrapper}
          >
            {/*<Button size="small" to="/projects">
              PHYSICAL
            </Button>
            <Divider orientation="vertical" flexItem sx={styles.divider} />
            <Button size="small" to="/digital">
              DIGITAL
            </Button>*/}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export const PostSlide = forwardRef((props, ref) => {
  const {
    imageStyle,
    post: {
      coverImage: { title, description, gatsbyImageData },
    },
  } = props;

  const image = getImage(gatsbyImageData);

  return (
    <div ref={ref}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <GatsbyImage
            image={image}
            title={title}
            alt={description}
            style={{ ...styles.imgSlide, ...imageStyle }}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export const PostContentSlide = forwardRef((props, ref) => {
  const { post, onClick } = props;
  const { textInImage } = post;

  return (
    <Box ref={ref} sx={styles.contentSlideRoot}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={styles.contentSlideWrapper}
      >
        <Grid item>
          <Typography
            sx={styles.contentSlideType}
            align="center"
            variant="body1"
          >
            {post.category ? post.category : 'News'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={
              textInImage
                ? styles.contentSlideTypeTextInImage
                : styles.contentSlideType
            }
            align="center"
            variant="h3"
          >
            {post.title}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" disableRipple onClick={onClick}>
            View
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
});

export const CoverImageSlide = forwardRef((props, ref) => {
  const {
    imageStyle,
    post: {
      coverImage: { title, description, gatsbyImageData },
    },
  } = props;

  const image = getImage(gatsbyImageData);

  return (
    <div ref={ref}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <GatsbyImage
            image={image}
            title={title}
            alt={description}
            style={{ ...styles.imgSlide, ...imageStyle }}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export const CoverTextSlide = forwardRef((props, ref) => {
  const {
    post: { headline, contentCollection },
    pageTitle,
    fullWidth,
    ...otherProps
  } = props;

  return (
    <div {...otherProps} ref={ref}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        sx={styles.coverTextSlideWrapper}
      >
        <Grid item xs={12}>
          <Typography align="center" variant="h3" sx={styles.coverTextTitle}>
            {pageTitle}
          </Typography>
        </Grid>

        {contentCollection.map((child, index) => (
          <Grid item xs={12} sm={fullWidth ? 12 : 6} key={'child-' + index}>
            <Markdown markdown={child.markdown} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
});
